var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v(_vm._s(_vm.$t('video.head')))])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('video.head_list'))+" ")]),_c('v-card-actions',{staticClass:"pb-4 d-flex align-end"},[_c('v-spacer'),_c('v-btn',{staticClass:"px-5",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push('/video/video_form_create')}}},[_c('v-icon',{staticClass:"px-3"},[_vm._v("mdi-plus")]),_c('span',{staticClass:"pr-3"},[_vm._v(_vm._s(_vm.$t('video.create'))+" ")])],1)],1),_c('v-card-actions',{staticClass:"pt-0"},[_c('v-data-table',{staticStyle:{"width":"100%"},attrs:{"mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.itemsData,"no-data-text":_vm.$t('not_found'),"no-results-text":_vm.$t('not_found'),"options":_vm.pagination,"server-items-length":_vm.pagination.totalItems,"items-per-page":_vm.pagination.rowsPerPage,"page":_vm.pagination.page,"footer-props":{
            'items-per-page-options': [10, 25, 30, 40, 50],
          },"loading":_vm.loading,"search":_vm.search},on:{"update:options":function($event){_vm.pagination=$event},"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)}},scopedSlots:_vm._u([{key:"item.enable",fn:function(ref){
          var item = ref.item;
return [(item.enable)?_c('span',{staticClass:"success--text"},[_vm._v(_vm._s(_vm.$t('open')))]):_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(_vm.$t('close')))])]}},{key:"item.created_at",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.created_at)))])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-menu',{attrs:{"transition":"slide-y-transition","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v("mdi-square-edit-outline")]),_c('span',{staticClass:"px-3 white--text"},[_vm._v(_vm._s(_vm.$t('video.manage')))]),_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.$router.push(("/video/video_form_update/" + (item.id)))}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":"","color":"warning"}},[_vm._v("mdi-square-edit-outline")]),_c('span',{staticClass:"px-4"},[_vm._v(_vm._s(_vm.$t('edit')))])],1)],1),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){return _vm.deleteVideo(item)}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v("mdi-delete")]),_c('span',{staticClass:"px-4"},[_vm._v(_vm._s(_vm.$t('delete')))])],1)],1)],1)],1)]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }